:root {
    --f7-theme-color: #ab283c !important;
    --f7-theme-color-rgb: 171, 40, 60 !important;
    --f7-theme-color-shade: #8a1020 !important;
    --f7-theme-color-tint: #cc1830 !important;
    --f7-font-family: 'Montserrat', sans-serif !important;

    --text-color: #878787 !important;
    --f7-block-title-medium-text-color: var(--text-color) !important;
    --f7-block-title-medium-text-transform: uppercase !important;
    --f7-block-title-medium-font-weight: 300 !important;
    --f7-block-title-medium-font-size: 1.2rem !important;
    --f7-block-title-white-space: break-word!important;

    --f7-page-master-width: calc(100vw / 3)!important;
    --f7-page-master-max-width: 430px!important;
    --f7-page-master-border-width: 0!important;

    --content-side-padding: 1rem;
    --border-radius: 1.5rem;

    --top-bar: 5rem;

    --small-width: 375px;
}

/* styling native f7 components */
.page {
    font-size: 14px;
    /*position: unset!important;*/
}

.block-title {
    margin-left: 0;
}

.page-current {
    background-size: cover;
    background-position: center;
}

.view-master-detail .page-master, .view-master-detail .navbar-master {
    min-width: calc(100% - var(--f7-page-master-width));
    width: 100%;
    max-width: calc(100% - var(--f7-page-master-max-width));
}

.view-master-detail .page-master-detail, .view-master-detail .navbar-master-detail {
    width: var(--f7-page-master-width);
    max-width: var(--f7-page-master-max-width);
    right: 0;
    left: auto;
}